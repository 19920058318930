style.css

h2 {
    font-size: 2rem; 
    font-weight: bold;
    color: black;
  }
  
  ol {
    font-size: 1.2rem; 
    line-height: 2;
    margin-left: 20px;
    color: black; 
  }
  
  ol li {
    margin-bottom: 20px; 
  }
  
  ol li strong {
    font-weight: bold;
    color: black; 
    font-size: 1.4rem;
    display: block; 
    display: inline; 
  
  }
  @media (max-width: 768px) {
    .container {
      padding: 8px;
    }
    
    h1 {
      font-size: 24px;
    }
    
    h3 {
      font-size: 18px;
    }
    
    ol > li {
      font-size: 16px;
    }
    
    ul {
      font-size: 14px;
    }
  }