/* ===================== HOT OFFERS ======================*/
#offerTab {
  text-align: center;
  width: 100%;
  display: block;
  border: medium none;
  margin: 30px 0;
}

#offerTab li {
  display: inline-block;
  margin: 0 5px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: medium none;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border: medium none;
}

.nav-tabs .nav-link {
  border: medium none;
}

#offerTab li.nav-item .nav-link,
#offerTab li.nav-item .nav-link.active {
  display: inline-block;
  width: 150px;
  height: 70px;
  text-transform: uppercase;
  padding: 7px 25px;
  font-weight: 500;
  color: #020202;
  font-size: 14px;
  position: relative;
  z-index: 1;
}

#offerTab li.nav-item .nav-link:after {
  position: absolute;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  background: #ffffff none repeat scroll 0 0;
  z-index: -1;
  /* -webkit-transform: skew(40deg);
  transform: skew(40deg);
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; */
}

#offerTab li.nav-item .nav-link:hover:after {
  background: #FFDA27
none repeat scroll 0 0;
  /* -webkit-transform: skew(-40deg);
  transform: skew(-40deg); */
}
#offerTab .nav-tabs {
  width: 100%;
  display: block;
  border: medium none;
  margin: 30px 0;
  justify-content: center;
}
#offerTab li.nav-item .nav-link.active:after {
  position: absolute;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: -1;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  background: #FFDA27
 none repeat scroll 0 0;
  /* -webkit-transform: skew(-40deg);
  transform: skew(-40deg); */
}

.single-offers {
  text-align: center;
  padding: 20px;
  border: 3px solid #f0f0ff;
  margin-top: 30px;
}

.offer-image {
  width: auto;
  /* height: 200px; */
  margin: 0 auto;
  display: block;
}

.offer-image img {
  width: 100%;
}

.offer-text {
  margin-top: 20px;
}

.offer-text h3 {
  font-size: 23px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 10px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.offer-text h4 {
  color: #020202;
  font-size: 18px;
  text-transform: capitalize;
  margin-top: 8px;
}

.offer-text h4 span {
  text-transform: capitalize;
  color: #FFDA27
;
  font-size: 16px;
}

.offer-btn-2{
  
  width: 100%;
  text-transform: uppercase;
  padding: 7px;
  color: #fff;
  background-color: #25D366;
}

.offer-btn-1{
  
  width: 100%;
  text-transform: uppercase;
  padding: 7px;
  color: #fff;
  background-color: #020202;
}

.offer-btn-1:hover{
  color: #fff;
  background-color: #25D366;
}

.watsapp-icon{
  width: 15;
}


.offer-btn-container{
  width: 100%;
  
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #020202 none repeat scroll 0 0;
  text-align: center;
}
.offer-btn-container:hover{
  color: #fff;
  background-color: #25D366;
}


.offer-action {
  text-align: center;
  background: #020202 none repeat scroll 0 0;
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  margin: 25px auto 0;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.offer-action a {
  display: inline-block;
  text-transform: uppercase;
  padding: 7px 15px;
  font-weight: 500;
  color: #fff;
  font-size: 14px;
  width: 47%;
}

.offer-action:after {
  position: absolute;
  content: "";
  width: 66%;
  height: 100%;
  background: #FFDA27
 none repeat scroll 0 0;
  right: -36px;
  z-index: -1;
  -webkit-transform: skewX(40deg);
  transform: skewX(40deg);
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.offer-action:hover {
  background: #FFDA27
 none repeat scroll 0 0;
}

.offer-action:hover:after {
  background: #020202 none repeat scroll 0 0;
}

.offer-text ul {
  text-align: center;
  margin-top: 10px;
}

.offer-text ul li {
  display: inline-block;
  font-size: 14px;
  margin: 0 3px;
}

.offer-text ul li svg {
  margin-right: 5px;
  fill: #FFDA27
;
}

.specification ul li img{
margin-right: 10px;
}

.custom-phone-input{
  border-radius: 0px;
}


.customDatePickerWidth, 
.customDatePickerWidth > div.react-datepicker-wrapper, 
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
   width: 100%;
}

.custom-phone-input:focus {
  outline: none;
  border-radius: 5px;
}

.custom-phone-input:focus {
  border: 1px solid #ced4da; /* Replace with your desired border color */
  box-shadow: 0 0 0px rgba(255, 218, 39, 1); 
}

.custom-datepicker-input:focus{
  border: 1px solid #ced4da; /* Replace with your desired border color */
  box-shadow: 0 0 0px rgba(255, 218, 39, 1); 
}

.custom-datepicker-container {
  width: 500px;
}

.custom-timepicker input {
  width: 80px; /* Adjust the width as needed */
}
@media (min-width: 768px) and (max-width: 991px) {
  .offer-tabs .row .col-lg-4:last-child {
    display: none;
  }
  .offer-tabs .row .col-lg-4,
  .gauto-blog-area .row .col-lg-4 {
    width: 50%;
  }
  .offer-action:after {
    width: 62%;
  }
}
@media (max-width: 767px) {
  #offerTab li {
    display: block;
    margin-bottom: 5px;
  }
  #offerTab li a {
    display: block !important;
  }
  #offerTab li.nav-item .nav-link.active:after,
  #offerTab li.nav-item .nav-link:after {
    -webkit-transform: skewX(0);
    transform: skewX(0);
  }
  #offerTab li.nav-item .nav-link:hover:after {
    -webkit-transform: skewX(0);
    transform: skewX(0);
  }
  .single-offers {
    padding: 10px;
  }
  .offer-text ul li {
    margin: 0 1px !important;
    display: inline-block !important;
  }
  .offer-text ul li i {
    margin-right: 1px;
  }
  #offerTab li.nav-item .nav-link {
    display: block;
    width: 100%;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .offer-action:after {
    width: 62%;
  }
  .single-offers {
    padding: 15px;
  }
  .offer-text ul li {
    margin: 0 3px;
  }
  .offer-text ul li i {
    margin-right: 3px;
  }
  #offerTab li {
    display: block;
    margin-bottom: 5px;
  }
  #offerTab li a {
    display: block !important;
  }
  #offerTab li.nav-item .nav-link.active:after,
  #offerTab li.nav-item .nav-link:after {
    -webkit-transform: skewX(0);
    transform: skewX(0);
  }
  #offerTab li.nav-item .nav-link:hover:after {
    -webkit-transform: skewX(0);
    transform: skewX(0);
  }
}
